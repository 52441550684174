input[type='text'].aa-hint {
	text-align: left;
	background: #fefefe !important;
}

.pretty-checkbox {
	background-color: blue;
}

label > [type='checkbox'],
label > [type='radio'] {
	margin-right: 0;
}

input[type='checkbox'] {
	height: 40px;
	width: 100%;
}

input[type='radio'] {
	border: 0px;
	width: 100%;
	height: 40px;
}

label.columns > input, label.columns > select, textarea, .button {
	box-shadow: 0 2px 3px 1px rgba(0, 0, 0, .2);
}

label.columns > input[type=checkbox], label.columns > input[type=radio] {
	box-shadow: none;
}

input[readonly] {
	text-align: center;
	&::-webkit-inner-spin-button,
	&::-webkit-calendar-picker-indicator {
		display: none;
		-webkit-appearance: none;
	}
}

label {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: flex-end;
	b, input, span, button, textarea, select {
		flex: 100%;
	}
	textarea {
		margin: 0 0 1em;
		padding: 0.5rem;
	}
}

label > b {
	white-space: pre-wrap;
}

.Select {
	width: 100%;
	box-shadow: 0 2px 3px 1px rgba(0,0,0,.2);
	margin: 0 0 1rem;
}

div.Select-control {
	border-radius: 0;
	height: 2.4375rem;
}

input[type='text'] {
	height: 40px;
	width: 100%;
}
