

.algolia-autocomplete {
	width: 100%;
	> input {
		margin-bottom: 1em;
		box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.1);
	}
}

.algolia-autocomplete .aa-hint,.algolia-autocomplete .aa-input {
	width: 100%;
	min-height: 30px;
}

.algolia-autocomplete .aa-hint {
	color: #999
}

.algolia-autocomplete .aa-dropdown-menu {
	width: 100%;
	background-color: #fff;
	border: 1px solid #999;
	border-top: none;
	transform: translateY(calc(-1em + -2px));
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion {
	cursor: pointer;
	padding: 5px 4px
}

.autocomplete {
	z-index: 99;
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion.aa-cursor {
	background-color: #b2d7ff
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion em {
	font-weight: 700;
	font-style: normal
}