.assign-order {
	margin-bottom: 1.5rem;
	&.first {
		.group-title {
			background: rgba(black,.6);
			font-size: 1.5rem;
		}
		.assign-order-child {
			margin-top: 1.5rem;
		}
	}
}

.assign-order .assign-order-child {
	box-shadow: 0 2px 4px 2px rgba(0, 0, 0, .35);
	padding-top: .5rem;
	padding-bottom: .5rem;
}
.assign-order:nth-child(odd) .assign-order-child {
	background: rgba(darken(red,30),.6);
}

.assign-order:nth-child(even) .assign-order-child {
	background: rgba(darken(blue,30),.6);
}