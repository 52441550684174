@import "../utilities/variables";

$background: rgba(255,255,255,.55);

@if $dark {
	$background: rgba(50,50,50,.55);
}

.with-margin {
	margin-bottom: 1.5rem;
}

.limit-height {
	overflow: auto;
}

.group {
	background-color: $background;
	box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.35);
	&-title {
		background-color: rgba(0, 0, 0, .3);
		padding: .3em;
		text-align: center;
		color: white;
	}
}
