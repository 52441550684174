@media screen and (max-width: 39.9375em) {
	.header-time {
		font-size: .8em;
	}
}
.floating-top-left {
	display: none;
}
@media screen and (min-width: 40.063em) {
	.floating-top-left {
		display: block;
		position: absolute;
		top: 0;
		left: 50%; /* position the left edge of the element at the middle of the parent */
		transform: translate(-50%); /* This is a shorthand of
                                         translateX(-50%) and translateY(-50%) */
	}
}