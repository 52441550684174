@import '~foundation-sites/scss/util/_unit';
@import "~foundation-sites/scss/util/breakpoint";
@import "../utilities/variables";

$perfect-shadow: 2px 0 3px 1px rgba(0, 0, 0, .2);

$font-color: black;
$background: white;
$background-fade: rgba(200,200,200,.1);
$current-menu: orange;
$transition: 350ms;

@if $dark {
	$font-color: white;
	$background: rgb(50,50,50);
	$background-fade: rgba(5,5,5,.1);
	$current-menu: greenyellow;
}

$current-menu-hover: lighten($current-menu,20%);
$background-hover: darken($background,20%);

nav#sidebar {
	color: $font-color;
	overflow: visible;
	transition: transform $transition;
	transform: translateX(-200px);
	z-index: 101;
	position: absolute;
	width: 200px;
	box-shadow: $perfect-shadow;
	height: calc(100vh - 75px);
	&.open {
		transform: translateX(0px);
	}
	@include breakpoint(medium) {
		&::before {
			box-shadow: $perfect-shadow;
			content: "M E N U";
			text-orientation: upright;
			writing-mode: vertical-rl;
			//white-space: pre;
			//word-wrap: break-word;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			width: 50px;
			height: 100%;
			background: $background;
			z-index: -1;
			left: 100%;
			transition: transform $transition, left $transition;
			cursor: pointer;
			&:hover {
				cursor: pointer;
			}
		}
		&.open {
			&::before {
				left: calc(100% - 50px);
				box-shadow: none;
			}
		}
	}
}

nav#sidebar {
	> ul {
		background-color: $background;
	}
}

nav {
	ul {
		background-color: $background-fade;
		overflow: auto;
		height: inherit;
		> div.nav-logo {
			padding: 1em;
		}
	}
	* {
		margin: 0;
	}
	ul, li {
		list-style-type: none;
		display: block;
	}
	.nav-item {
		display: flex;
		padding-left: .5em;
		border: solid 1px rgba(0, 0, 0, .1);
		position: relative;
		transition: all 175ms ease-out;
		&.no-click {
			background-color: rgb(80, 80, 80);
		}
		&-text {
			flex: 70%;
			a {
				display: block;
				color: $font-color;
			}
		}
		&-icon {
			width: 40px;
			background-color: whitesmoke;
			display: flex;
			align-items: center;
			justify-content: center;
			> svg {
				height: 1em;
				fill: black;
			}
		}
		.current-menu {
			color: $current-menu !important;
		}
		&:not(.no-click):hover {
			cursor: pointer;
			background-color: $background-hover;
			a {
				color: $font-color;
			}
			.current-menu {
				color: $current-menu !important;
			}
		}
		&-icon {
			transition: all 175ms;
			&:hover {
				cursor: pointer;
				background: darken($background-hover,20%);
				> svg {
					fill: white;
				}
			}
		}
	}
}