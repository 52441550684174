.map {
	height: 300px;
	margin: 1em;
	width: calc(100% - 2em);
	box-shadow: 0px 1px 5px 2px rgba(0,0,0,.2);
}


.controls {
	margin-top: 10px;
	border: 1px solid transparent;
	border-radius: 2px 0 0 2px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	height: 32px;
	outline: none;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);


	background-color: #fff;
	font-family: Roboto;
	font-size: 15px;
	font-weight: 300;
	margin-left: 12px;
	padding: 0 11px 0 13px;
	text-overflow: ellipsis;
	width: 300px;

	&:focus {
		border-color: #4d90fe;
	}
}

.map-container {
	width: 100%;
}