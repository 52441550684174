.nav-enter {
  transform: translateX(-200px);
}

.nav-container {
  transition: all 500ms;
}

.nav-enter.nav-enter-active {
  transform: translateX(0);
  transition: all 250ms ease-in;
}

.nav-leave {
  transform: translateX(0);
}

.nav-leave.nav-leave-active {
  transform: translateX(-200px);
  transition: all 250ms ease-in;
}
