div.nanobar {
	width: 100%;
	height: 4px;
	z-index: 9999;
	top: 0;
	overflow: hidden;
	position: fixed;
}

.bar {
	width: 0;
	height: 100%;
	transition: height .3s;
	background:#000;
}

.nanobar .bar {
	background: #35ff57;
}

//.nanobar .bar {
//	background: #35ff57;
//	border-radius: 4px;
//	box-shadow: 0 0 10px #6add81;
//	margin: 0 auto;
//}
//
//.bar {
//	width: 0;
//	height: 100%;
//	transition: height .3s;
//	background: #000;
//}