$color: orange;

.consult-form {
	box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.5);
	&.cancelado.columns {
		background-color: $color;
		label {
			color: white;
		}
		&::after {
			content: "C A N C E L A D O";
			color: white;
			display: block;
			background-color: $color;
			left: 0;
			text-align: center;
			top: -.2em;
			font-size: 2.5em;
		}
	}

	&:nth-child(odd) {
		background-color: rgba(113, 225, 113, 0.4);
	}
	&:nth-child(even) {
		background-color: rgba(115, 115, 225, 0.4);
	}
}