.upper-container {
	cursor: wait;
	position: fixed;
	z-index: 10000;
	background-color: rgba(20, 20, 20, .4);
	top: 0;
	left: 0;
	padding-right: 1em;
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
}

.loaders-container {
	align-self: center;
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
}
.loader {
	align-self: center;
	text-indent: -9999em;
	position: absolute;
	border: 1.1em solid rgba(white, 0.2);
	border-left-color: white;
	box-shadow: 0 0 2px 2px rgba(white,.3);
	&-1 {
		font-size: 3px;
		animation: load8 .6s infinite ease-in-out;
	}
	&-2 {
		font-size: 6px;
		animation: load8 .8s infinite ease-in-out;
	}
	&-3 {
		font-size: 12px;
		animation: load8 1.4s infinite ease-in-out;
	}
}

.loader,
.loader:after {
	border-radius: 50%;
	width: 10em;
	height: 10em;
}



@-webkit-keyframes load8 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes load8 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}