$dark: true;

$fill: rgb(30,30,30);

@if $dark{
	$fill: white;
}

.access-card-parent {
	padding: 1em;
	width: 20%
}
.access-card {
	//margin: 1em;
	transition: all 175ms;
	&:hover {
		transform: translateY(-5px);
		box-shadow: 4px 7px 4px 3px rgba(0, 0, 0, 0.35);
		svg {
			fill: rgba($fill,.6);
		}
	}
	svg {
		width: 100%;
		transition: all 175ms;
		fill: rgba($fill,.3);
	}
	&-link {
		font-size: 1.1em;
	}
}