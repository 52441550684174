@charset "UTF-8";
/* line 6, ../sass/_content.sass */
.offline-ui .offline-ui-retry:before {
  content: "Reconectar"; }

/* line 11, ../sass/_content.sass */
.offline-ui.offline-ui-up .offline-ui-content:before {
  content: "Tu computador está conectado a internet."; }

@media (max-width: 1024px) {
  /* line 11, ../sass/_content.sass */
  .offline-ui.offline-ui-up .offline-ui-content:before {
    content: "Tu dispositivo está conectado a internet."; } }

@media (max-width: 568px) {
  /* line 11, ../sass/_content.sass */
  .offline-ui.offline-ui-up .offline-ui-content:before {
    content: "Tu dispositivo está conectado."; } }

/* line 22, ../sass/_content.sass */
.offline-ui.offline-ui-down .offline-ui-content:before {
  content: "Tu computador perdió su conexión a internet."; }

@media (max-width: 1024px) {
  /* line 22, ../sass/_content.sass */
  .offline-ui.offline-ui-down .offline-ui-content:before {
    content: "Tu dispositivo perdió su conexión a internet."; } }

@media (max-width: 568px) {
  /* line 22, ../sass/_content.sass */
  .offline-ui.offline-ui-down .offline-ui-content:before {
    content: "Tu dispositivo no está conectado."; } }

/* line 33, ../sass/_content.sass */
.offline-ui.offline-ui-down.offline-ui-connecting .offline-ui-content:before, .offline-ui.offline-ui-down.offline-ui-connecting-2s .offline-ui-content:before {
  content: "Intentando reconectar..."; }

/* line 42, ../sass/_content.sass */
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="second"]:before {
  content: "Conexión perdida. Reconectando en " attr(data-retry-in-value) " segundos..."; }

@media (max-width: 568px) {
  /* line 42, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="second"]:before {
    content: "Reconectando en " attr(data-retry-in-value) "s..."; } }

/* line 50, ../sass/_content.sass */
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="second"][data-retry-in-value="1"]:before {
  content: "Conexión perdida. Reconectando en " attr(data-retry-in-value) " segundo..."; }

@media (max-width: 568px) {
  /* line 50, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="second"][data-retry-in-value="1"]:before {
    content: "Reconectando en " attr(data-retry-in-value) "s..."; } }

/* line 58, ../sass/_content.sass */
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="minute"]:before {
  content: "Conexión perdida. Reconectando en " attr(data-retry-in-value) " minutos..."; }

@media (max-width: 568px) {
  /* line 58, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="minute"]:before {
    content: "Reconectando en " attr(data-retry-in-value) "m..."; } }

/* line 66, ../sass/_content.sass */
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="minute"][data-retry-in-value="1"]:before {
  content: "Conexión perdida. Reconectando en " attr(data-retry-in-value) " minuto..."; }

@media (max-width: 568px) {
  /* line 66, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="minute"][data-retry-in-value="1"]:before {
    content: "Reconectando en " attr(data-retry-in-value) "m..."; } }

/* line 74, ../sass/_content.sass */
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="hour"]:before {
  content: "Conexión perdida. Reconectando en " attr(data-retry-in-value) " horas..."; }

@media (max-width: 568px) {
  /* line 74, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="hour"]:before {
    content: "Reconectando en " attr(data-retry-in-value) "h..."; } }

/* line 82, ../sass/_content.sass */
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="hour"][data-retry-in-value="1"]:before {
  content: "Conexión perdida. Reconectando en " attr(data-retry-in-value) " hora..."; }

@media (max-width: 568px) {
  /* line 82, ../sass/_content.sass */
  .offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="hour"][data-retry-in-value="1"]:before {
    content: "Reconectando en " attr(data-retry-in-value) "h..."; } }

/* line 90, ../sass/_content.sass */
.offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s.offline-ui-waiting .offline-ui-retry {
  display: none; }

/* line 93, ../sass/_content.sass */
.offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s .offline-ui-content:before {
  content: "Intento fallido."; }
