.modal-enter {
	opacity: 0;
	.modal-center {
		transform: translateX(-100%);
	}
}

.modal-enter.modal-enter-active {
	opacity: 1;
	transition: opacity 300ms ease-out;
	.modal-center {
		transition: transform 300ms ease-out;
		transform: translateX(0%);
	}
}

.modal-leave {
	opacity: 1;
	.modal-center {
		transform: translateX(0%);
	}
}

.modal-leave.modal-leave-active {
	opacity: 0;
	transition: opacity 300ms ease-in;
	.modal-center {
		transition: transform 300ms ease-in;
		transform: translateX(100%);
	}
}

.modal-bg {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .4);
	z-index: 999;
}

.modal-center {
	max-height: 80vh;
	background-color: rgba(255, 255, 255, 0.85);
	padding: 2em;
	box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.5);
	position: relative;
}

.modal-close {
	position: absolute;
	top: 0;
	right: 0;
	width: 2em;
	height: 2em;
	background-color: rgba(0, 0, 0, .6);
	text-align: center;
	font-weight: bold;
	transition: all .2s;
	line-height: 2em;
	fill: white;
	&:hover {
		background-color: white;
		fill: rgba(0, 0, 0, .6);
		cursor: pointer;
	}
}

.modal-drag {
	position: absolute;
	top: 0;
	left: 0;
	width: 2em;
	height: 2em;
	background-color: rgba(0, 0, 0, .6);
	fill: white;
	text-align: center;
	font-weight: bold;
	transition: all .2s;
	line-height: 2em;
	cursor: move;
	padding: 0.3rem;
}
