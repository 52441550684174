.version {
	color: white;
	text-shadow: 1px 1px 3px black;
}

div.react-grid-Main {
	background-color: rgba(50,50,50,.3);
	color: white;
}
div.react-grid-Grid {
	background-color: transparent;
	border: none;
}
div.react-grid-Header {
	background-color: transparent;
}
input.react-grid-checkbox + .react-grid-checkbox-label:before,
input.radio-custom + .radio-custom-label:before {
	background-color: transparent
}

div.react-grid-Row:hover div.react-grid-Cell,
div.react-grid-Row.row-context-menu div.react-grid-Cell,
div.react-grid-Row.row-selected,
div.react-grid-Row.row-selected div.react-grid-Cell,
div.react-grid-Cell.selected {
	background-color: lighten(rgba(50,50,50,.3),35%);
}
div.react-grid-Canvas {
	background-color: transparent;
}

div.react-grid-Cell {
	background-color: transparent;
}
div.react-grid-HeaderCell {
	//overflow: hidden;
	background-color: transparent;
}

div.react-grid-HeaderCell.react-grid-HeaderCell--locked,
div.react-grid-Cell.react-grid-Cell--locked,
div.react-grid-Row.row-selected div.react-grid-Cell.react-grid-Cell--locked,
div.react-grid-Row.row-selected div.react-grid-Cell.react-grid-Cell--locked.selected,
div.react-grid-Row:hover div.react-grid-Cell.react-grid-Cell--locked {
	background-color: rgb(50,50,50);
}

//TODO: background transparent, font color white
//TODO: remove css react data grid duplication

label > b {
	color: white;
	text-shadow: 1px 1px #0a0a0a;
}

/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */

//.content:before {
//	background-color: rgb(25,25,25);
//	background-image: url("https://www.transparenttextures.com/patterns/diagmonds.png");
//}

#snow {
	z-index: 0;
	background: url('../img/s1.png'), url('../img/s2.png'), url('../img/s3.png');
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	animation: snow 10s linear infinite;
}

@keyframes snow {
	0% {
		background-position: 0px 0px, 0px 0px, 0px 0px;
	}
	50% {
		background-position: 500px 500px, 100px 200px, -100px 150px;
	}
	100% {
		background-position: 500px 1000px, 200px 400px, -100px 300px;
	}
}
