.react-grid-checkbox-container {
	height: 100%;
	line-height: normal;
}

label.react-grid-checkbox-label {
	display: inline-block;
	vertical-align: middle;
	margin: 10px;
	cursor: pointer;
	top: 0;
	font-size: 18px;
	&::before {
		content: '';
		background: #fff;
		border: 2px solid #ddd;
		display: inline-block;
		position: absolute;
		vertical-align: middle;
		width: 20px;
		height: 20px;
		text-align: center;
		top: -0.6em;
	}
}

input[type="checkbox"].react-grid-checkbox {
	margin: 4px 0 0;
	line-height: normal;
	box-sizing: border-box;
	padding: 0;
	width: 13px;
	height: 13px;
	& + label.react-grid-checkbox-label:before {
		top: -0.6em;
	}
	&:checked + label.react-grid-checkbox-label:before {
		content: '\2714';
		position: absolute;
		line-height: 0.8;
		color: #09ad7e;
		background: none;
		box-shadow: none;
	}
}

.react-grid-HeaderCell {
	.react-grid-checkbox-container {
		label.react-grid-checkbox-label {
			top: 0;
		}
	}
}

div.checkbox-rdg-container {
	display: flex;
	line-height: normal;
	height: 35px;
	input.checkbox-rdg-input {
		height: 0;
		width: 0;
		margin: 0 auto;
		&::before, &:checked::before {
			//35 px because the height of container
			margin: calc( (35px - 20px) / 2) -10px;
			content: '';
			background: none;
			box-shadow: none;
			border: 2px solid #ddd;
			display: inline-block;
			position: absolute;
			vertical-align: middle;
			width: 20px;
			height: 20px;
			text-align: center;
			color: #09ad7e;
			line-height: 0.8;
			font-size: 18px;
		}
		&:checked::before {
			content: '\2714';
		}
	}
}

div.react-grid-Toolbar {
	background-color: transparent;
}
