@import '~foundation-sites/scss/util/_unit';
@import "~foundation-sites/scss/util/breakpoint";
@import "../utilities/variables";

$background: rgba(155, 155, 155,.76);

@if $dark {
	$background: rgb(50,50,50);
}

header {
	background-color: $background;
	font-weight: bold;
	color: white;
	text-shadow: 1px 1px black;
	box-shadow: 0 0 2px 3px rgba(0, 0, 0, .4);
	z-index: 999;
	position: fixed;
	width: 100%;
	height: 75px;
	> h1 {
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		cursor: pointer;
		> div {
			width: 100%;
			> img {
				max-height: 75px;
			}
		}
	}
}

#menu {
	position: fixed;
	margin-top: 75px;
	z-index: 100;
}

main.columns {
	max-height: calc(100vh - 75px);
	overflow-y: scroll;
	overflow-x: auto;
	&.wide {
		padding: 1rem;
		padding-left: calc(50px + 1rem);
	}
}

@include breakpoint(small only) {
	div.modal-center {
		max-height: 100vh;
		height: 100vh;
	}
}

@include breakpoint(small) {
	main.columns {
		padding: 1em 1em;
	}
}

@include breakpoint(medium) {
	main.columns {
		padding: 1em 100px;
	}
}

@include breakpoint(large) {
	main.columns {
		padding: 1em calc(200px + 1em);
	}
}

main.columns {
	min-height: calc(100vh - 75px);
	margin-top: 75px;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

div.react-grid-Cell, .react-grid-Cell__value {
	overflow: hidden;
}